import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, MenuItem, Typography, useTheme } from '@mui/material';
import { FormTextField } from '../../ui';
import { CountryCode } from '../../generated/graphql';
import { PendingAccount } from './types';

export type PendingAccountFormValues = PendingAccount;

export interface PendingAccountFormProps {
  initialValues: PendingAccountFormValues;
  onSubmit: (values: PendingAccountFormValues) => void;
}

const pendingAccountFormValidation = yup.object().shape({
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  venueName: yup.string().required(),
  venueCountry: yup.string().required(),
});

export const PendingAccountForm: React.FC<PendingAccountFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const theme = useTheme();

  const handleSubmit = (
    values: PendingAccountFormValues,
    { resetForm }: FormikHelpers<PendingAccountFormValues>,
  ) => {
    onSubmit(values);
    resetForm();
  };

  return (
    <Formik<PendingAccountFormValues>
      initialValues={initialValues}
      validationSchema={pendingAccountFormValidation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Box display="flex" flexDirection="column" gap={0}>
            <FormTextField
              required
              id="venueName"
              name="venueName"
              label="Venue Name"
            />
            <FormTextField required id="email" name="email" label="Email" />
            <Box display="flex" gap={2}>
              <FormTextField
                required
                id="firstName"
                name="firstName"
                label="First name"
              />
              <FormTextField
                required
                id="lastName"
                name="lastName"
                label="Last name"
              />
            </Box>
            <FormTextField
              select
              required
              id="venueCountry"
              name="venueCountry"
              label="Country"
            >
              {[
                CountryCode.CzechRepublic,
                CountryCode.Slovakia,
                CountryCode.Poland,
                CountryCode.UnitedKingdom,
              ].map((country) => (
                <MenuItem
                  key={country}
                  value={country}
                  onClick={() => setFieldValue('venueCountry', country)}
                >
                  {country}
                </MenuItem>
              ))}
            </FormTextField>
            <Typography color={theme.palette.text.secondary} mt={2}>
              This form will immediately create the provided venue, but it will
              not yet send the login creadentials to the user. Instead, it will
              be placed into the Pending list on the right where you can Send,
              Edit, or Delete the new account later.
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <Button disabled={isSubmitting} variant="contained" type="submit">
                Confirm
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
